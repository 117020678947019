import React from 'react';
import { InputNumber } from 'antd';

import { ScheduleType } from 'project/Defines';
import DropDownValueList from '~/Shared/DropDown';
import { T } from '~/components/Translations';

/**
 *component generating each row for shcedule list
 */
export default function ScheduleItem({selectedScheduleType, scheduleItemRemoved, scheduleItems, setScheduleItems, item, showHeader}) {
    const SECOND = selectedScheduleType === ScheduleType.SECOND;
    const MINUTE = selectedScheduleType === ScheduleType.MINUTE;
    const HOUR = selectedScheduleType === ScheduleType.HOUR;
    const DAILY = selectedScheduleType === ScheduleType.DAILY;
    const WEEKLY = selectedScheduleType === ScheduleType.WEEKLY;
    const MONTHLY = selectedScheduleType === ScheduleType.MONTHLY;
    const YEARLY = selectedScheduleType === ScheduleType.YEARLY;

    function onChange(value, label) {
        const _scheduleItems = [...scheduleItems];
        const _item = {...item};
        const itemIndex = scheduleItems.findIndex(x  => x === item);

        _item[label] = value;

        if (itemIndex !== -1) {
            _scheduleItems.splice(itemIndex, 1, _item);
            setScheduleItems(_scheduleItems);
        }
    }

    return (
        <>
            {
                SECOND && (
                    <div className="form_fields">
                        {
                            showHeader && <div className="data_label"><T>text.seconds</T></div>
                        }
                        <InputNumber
                            min={1}
                            max={10000}
                            value={item.second}
                            onChange={value => onChange(value, 'second')} // NOSONAR
                        />
                    </div>
            )
            }
            {
                MINUTE && (
                    <div className="form_fields">
                        {
                            showHeader && <div className="data_label"><T>text.minute</T></div>
                        }
                        <InputNumber
                            min={1}
                            max={10000}
                            value={item.minute}
                            onChange={value => onChange(value, 'minute')} // NOSONAR
                        />
                    </div>
                )
            }
            {
                HOUR && (
                    <div className="form_fields">
                        {
                            showHeader && <div className="data_label"><T>text.hour</T></div>
                        }
                        <InputNumber
                            min={1}
                            max={10000}
                            value={item.hour}
                            onChange={value => onChange(value, 'hour')} // NOSONAR
                        />
                    </div>
                )
            }
            {
                (YEARLY) && (
                    <div className="form_fields">
                        {
                            showHeader && <div className="data_label"><T>text.month</T></div>
                        }
                        <DropDownValueList
                            valuelist="Months"
                            showSearch
                            placeholder="Month"
                            value={item.month}
                            searchOptions={{
                                noAccent: true,
                                contains: true
                            }}
                            onChange={value => onChange(value, 'month')} // NOSONAR
                        />
                    </div>
                )
            }
            {
                (WEEKLY) && (
                    <div className="form_fields">
                        {
                            showHeader && <div className="data_label"><T>text.week</T></div>
                        }
                        <DropDownValueList
                            valuelist="WeekDays"
                            showSearch
                            placeholder="WeekDay"
                            value={item.week}
                            searchOptions={{
                                noAccent: true,
                                contains: true
                            }}
                            onChange={value => onChange(value, 'week')} // NOSONAR
                        />
                    </div>
                )
            }
            {
                (MONTHLY || YEARLY) && (
                    <div className="form_fields">
                        {
                            showHeader && <div className="data_label"><T>text.day</T></div>
                        }
                        <DropDownValueList
                            valuelist="MonthDays"
                            showSearch
                            placeholder="Day"
                            // style={{ width: 80, margin: '5px 0px 5px 5px' }}
                            value={item.day}
                            optionFilterProp="children"
                            searchOptions={{
                                noAccent: true,
                                contains: true
                            }}
                            onChange={value => onChange(value, 'day')} // NOSONAR
                        />
                    </div>
                )
            }
            {
                (DAILY || WEEKLY || MONTHLY || YEARLY) && (
                    <>
                        <div className="form_fields">
                            {
                                showHeader && <div className="data_label"><T>text.hour</T></div>
                            }
                            <DropDownValueList
                                valuelist="Hours"
                                showSearch
                                placeholder="Hour"
                                value={item.hour}
                                searchOptions={{
                                    noAccent: true,
                                    contains: true
                                }}
                                onChange={value => onChange(value, 'hour')} // NOSONAR
                            />
                        </div>
                        <div className="form_fields">
                            {
                                showHeader && <div className="data_label"><T>text.minute</T></div>
                            }
                            <DropDownValueList
                                valuelist="Minutes"
                                showSearch
                                placeholder="Minute"
                                value={item.minute}
                                searchOptions={{
                                    noAccent: true,
                                    contains: true
                                }}
                                onChange={value => onChange(value, 'minute')} // NOSONAR
                            />
                        </div>
                            {
                                (scheduleItems.length > 1) && (
                                        <action>
                                            <div className="button mini" onClick={scheduleItemRemoved}>
                                                <icon>delete</icon>
                                            </div>
                                        </action>
                                )
                            }
                    </>
                )
            }
        </>
    )
}