import { message } from 'antd';
import { Project } from '../project/Project';
import store from '../store';
import { logout, changeApplicationToken } from '../store/actions';

const api_url = process.env.REACT_APP_DEV_API_URL ?? process.env.REACT_APP_API_URL;
const api_app = process.env.REACT_APP_DEV_API_APP ?? process.env.REACT_APP_API_APP;

function doProcess(options, method) { // NOSONAR
    const formData = new FormData();
    const controller = new AbortController();
    const { signal } = controller;

    function buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
            if (parentKey && Object.keys(data).length === 0) {
                formData.append(parentKey, '');
            }
        } else {
            const value = data === null ? '' : data;

            formData.append(parentKey, value);
        }
    }

    let url = (options.absolute ? "" : (api_url + api_app || "")) + options.url;
    if (method === "GET" && options.data) {
        const params = [];
        Object.keys(options.data).forEach(key => params.push(key + "=" + options.data[key]));
        if (params.length > 0) {
            url += "?" + params.join("&");
        }
    }

    const fetchOptions = { 
        method: method || "POST"
    }
    if (options.useBody) {
        fetchOptions.body = options.data;
    }
    if (method === "POST") {
        buildFormData(formData, options.data);
        fetchOptions.body = formData;
    }

    let token = store.getState().userToken || "test";
    let app_token = store.getState().appToken;


    let headers = {
        //'USER-TOKEN': token,
        //'CULTURE': store.getState().culture,
        'Authorization': 'Bearer ' + (token !== "test" ? token : app_token),
        //'Accept-Language': 'de'
        //'Access-Control-Allow-Headers': 'culture, Access-Control-Allow-Headers, Access-Control-Allow-Origin',
        //'Access-Control-Allow-Origin': "http://localhost:3001",
        //'Vary': 'Origin',
    };
    let params = {
        ...fetchOptions, signal, 
        //mode: 'cors',
        headers: headers
        
    }

    if (options.headers){
        params["headers"] = options.headers;
    }

    url = Project.appendToUrl(url, "lang", store.getState().culture);

    console.log(url, params);

    if (options.download) {
        fetch(url, params)
            .then(response => response.blob())
            .catch(function (error) {
                if (options.error)
                    options.error(error);
                else
                    message.error(error.message);
            })
            .then(blob => {
                if (blob && blob.type) {
                    try {


                        if (blob.type === "application/problem+json" || blob.type === "application/json") {
                            let reader = new FileReader();
                            reader.readAsText(blob);
                            reader.onload = function () {
                                const error = JSON.parse(reader.result);
                                if (options.error)
                                    options.error(error);
                                else
                                    message.error(error.title || error.message);
                            };
                            return;
                        }

                        if (options.autoDownload) {
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = url;
                            if (options.target)
                                a.target = options.target;
                            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                            a.click();
                            a.remove();  //afterwards we remove the element again
                        }

                        if (options.success)
                            options.success(blob);
                    }
                    catch (e) {
                        if (options.error)
                            options.error(e);
                        else
                            message.error(e.message);
                    }
                }
            });
    }
    else {

        fetch(url, params)
            .then((response) => {
                if (response && response.status === 401){
                    if (token !== "test") {
                        store.dispatch(logout());
                    } else {
                        Project.removeLocalStorage("appToken");
                        store.dispatch(changeApplicationToken(null));
                    }
                    if (options.error)
                        options.error(response);
                    else
                        window.location.reload();
                }
                return response.json()
            })
            .catch(function (error) {
            })
            .then((result) => {
                if (result && result.hasError) {
                    if (result.customErrorCode) {
                        const errorCode = result.customErrorCode
                        switch (errorCode) {
                            case "Unauthorized":
                            case "401":
                                store.dispatch(logout());
                                if (options.error)
                                    options.error(result);
                                else
                                    window.location.reload();
                                break;

                            case "UnauthorizedAccess":
                            case "800":
                                //window.location.href = Project.getPageUrl(AppPages.AccessDenied) // NOSONAR
                                break;
                            //  case "00010":
                            //        if (!options.skipRefresh) {
                            //            AppManager.refresh(options);
                            //        }
                            //        else {
                            //            AppManager.openLoginWindow(options);
                            //        }
                            //        break;
                            //    case "00011":
                            //        AppManager.openLoginWindow(options);
                            //        break;

                            //    default:
                            //        if (options.showFailure && xhr.statusText != "abort") {
                            //            var message = xhr.getResponseHeader("CustomErrorMessage");
                            //            if (message) {
                            //                Message.error(message, { desc: xhr.getResponseHeader("CustomErrorDescription") }, null, true);
                            //            }
                            //            else if (options.showFailure) {
                            //                Message.failure();
                            //            }
                            //        } break;
                            default:
                                break;
                        }

                        return;
                    }

                    if (options.error)
                        options.error(result);
                    else
                        message.error(result.message);
                }
                //else if (result && result.redirectUrl) {
                //    window.location.href = result.redirectUrl;
                //}
                else if (result && result.status === 500) {
                    if (options.error)
                        options.error(result);
                    else
                        message.error(result.title);
                }
                else {
                    options.success(result || null);
                }
            })
    }
    return controller;
};

export const AjaxPost = function (options) {
    return doProcess(options, "POST");
};
export const AjaxGet = function (options) {
    return doProcess(options, "GET");
};
export const AjaxDownload = function (options) {
    return doProcess({ ...options, download: true }, "POST");
};
export const AjaxDownloadGet = function (options) {
    return doProcess({ ...options, download: true }, "GET");
};

export const AjaxUpload = function (options) {
    const formData = new FormData();
    formData.set('file', options.file);

    fetch(options.url, {
        // Your POST endpoint
        //credentials: 'include',
        mode: 'cors',
        method: 'POST',
        headers: {
            // Content-Type may need to be completely **omitted**
            // or you may need something
            //"Content-Type": "multipart/form-data",
            //'USER-TOKEN': token,
            //'CULTURE': store.getState().culture
        },
        body: formData // This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        request => {
            //console.log(request) // Handle the success response object
            if (options.success)
                options.success(request);
        }
    ).catch(
        error => {
            console.log(error) // Handle the error response object

            if (options.error)
                options.success(error);
        }
    );


};

export const AjaxDownloadImage = function (options) {
    fetch(options.url, {
        headers: {
          'Authorization':`Bearer ${store.getState().userToken}`,
        },
        method: "GET"
      })
      .then(response => response.blob())
      .then(blob => {
            const imageUrl = URL.createObjectURL(blob);
            options.success(imageUrl);
    });
};

export const AjaxGetLanguagesXMLHttp = function (options) {
    const formData = new FormData(); 
    const request = new XMLHttpRequest(); 

    request.open("POST", options.url); //, false);

    formData.append('valuelist', 'languages');
    request.setRequestHeader('Authorization', 'Bearer ' + options.token);
    request.send(formData);

    if (request.status === 200) {
        let list = JSON.parse(request.responseText);
        return list;
    }

    return [];
}

export const AjaxGetLanguagesFetch = function (options) {
    let fd =  "valuelist=languages";
            let headers = { 
                'Authorization':`Bearer ${options.token}`,
                "Content-Type": "application/x-www-form-urlencoded" 
            };
            const fetchOptions = { 
                method: "POST",
                body: fd
            };
            let params = {
                ...fetchOptions, 
                headers: headers
            };

    return fetch(options.url, params).then(
        response => response.json() // if the response is a JSON object
    ).then(
        response => {
            //console.log(request) // Handle the success response object
            options.callback && options.callback(response)
        }
        
    );
}

export const Ajax = {
    post: (options) => AjaxPost(options),
    get: (options) => AjaxGet(options),
    download: (options) => AjaxDownload(options),
    downloadGet: (options) => AjaxDownloadGet(options),
    downloadImage: (options) => AjaxDownloadImage(options),
    upload: (options) => AjaxUpload(options),
};




