import React, { useState } from 'react';

import { FormField } from '~/Shared/Form/formFields.js';
import { T } from '~/components/Translations.js';

import PanierExternePaymentForm from './PanierExternePaymentForm';
import { ValidationInput } from '../../../Shared/Form/validation';

export default function PanierExterneForm({ dataItem, onChangeData, isChecked, setIsChecked, submitData, color, universe, validation }) { // NOSONAR
    const [showAddress, setShowAddress] = useState(true);

    const onSelect = () => {
        setShowAddress(!showAddress);
    }

    return <>
        <view vertical="vertical">
            <view space="space" intro="intro" className={universe}>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections wide'>
                            <div className="form_fields" onClick={onSelect}>
                                <div className="input_cont">
                                    <badge accent="accent" filled="filled" l_space="l_space" style={{ backgroundColor: color }}>1</badge>
                                    <text style={{ color: color }}><b><T>text.address_facturation</T></b></text>
                                    <i></i>
                                </div>
                            </div>
                            {
                                dataItem && showAddress &&
                                <>
                                    <FormField label="text.raison_sociale">
                                        <ValidationInput validation={validation} name='adresse_facturation.raison_sociale' type='text' value={dataItem.adresse_facturation && (dataItem.adresse_facturation.raison_sociale || '')} onChange={onChangeData} disabled={dataItem.is_work_order} />
                                    </FormField>
                                    <div className="field_group">
                                        <FormField label="text.email">
                                            <ValidationInput validation={validation} name='email' type='text' value={dataItem.email || ''} onChange={onChangeData} disabled={dataItem.is_work_order} />
                                        </FormField>
                                        <FormField label="text.telephone">
                                            <ValidationInput validation={validation} name='telephone' type='text' value={dataItem.telephone || ''} onChange={onChangeData} disabled={dataItem.is_work_order} />
                                        </FormField>
                                    </div>
                                    <div style={{ marginLeft: "10px" }}>
                                        <label classic="">
                                            <input type="checkbox" checked={isChecked.address} onChange={() => setIsChecked(prev => ({ ...prev, address: !prev.address }))} disabled={dataItem.is_work_order} />
                                            <box><check></check></box>
                                            <text><T>text.address_de_livrasion_identique</T></text>
                                        </label>
                                    </div>
                                    <FormField label="text.address">
                                        <ValidationInput validation={validation} name='adresse_facturation.adresse' type='text' value={dataItem.adresse_facturation && (dataItem.adresse_facturation.adresse || '')} onChange={onChangeData} disabled={dataItem.is_work_order} />
                                    </FormField>
                                    <FormField label="text.complement_address">
                                        <input name='adresse_facturation.complement' type='text' value={dataItem.adresse_facturation && (dataItem.adresse_facturation.complement || '')} onChange={onChangeData} disabled={dataItem.is_work_order} />
                                    </FormField>
                                    <div className="field_group">
                                        <FormField label="text.code_postal">
                                            <ValidationInput validation={validation} name='adresse_facturation.cp' type='text' value={dataItem.adresse_facturation && (dataItem.adresse_facturation.cp || '')} onChange={onChangeData} disabled={dataItem.is_work_order} />
                                        </FormField>
                                        <FormField label="text.ville">
                                            <ValidationInput validation={validation} name='adresse_facturation.ville' type='text' value={dataItem.adresse_facturation && (dataItem.adresse_facturation.ville || '')} onChange={onChangeData} disabled={dataItem.is_work_order} />
                                        </FormField>
                                    </div>
                                </>
                            }
                            {
                                dataItem && !isChecked.address &&
                                <>
                                    <div className="form_fields" onClick={onSelect}>
                                        <div className="input_cont">
                                            <badge accent="accent" filled="filled" l_space="l_space" style={{ backgroundColor: color }}>2</badge>
                                            <text style={{ color: color }}><b><T>{dataItem.is_work_order ? "text.address_intervention" : "text.address_livrasion"}</T></b></text>
                                            <i></i>
                                        </div>
                                    </div>
                                    {
                                        !showAddress &&
                                        <>
                                            <FormField label="text.raison_sociale">
                                                <ValidationInput validation={validation}  name='adresse_livraison.raison_sociale' type='text' value={dataItem.adresse_livraison && (dataItem.adresse_livraison.raison_sociale || '')} onChange={onChangeData} disabled={dataItem.is_work_order} />
                                            </FormField>
                                            <FormField label="text.address">
                                                <ValidationInput validation={validation}  name='adresse_livraison.adresse' type='text' value={dataItem.adresse_livraison && (dataItem.adresse_livraison.adresse || '')} onChange={onChangeData} disabled={dataItem.is_work_order} />
                                            </FormField>
                                            <FormField label="text.complement_address">
                                                <input name='adresse_livraison.complement' type='text' value={dataItem.adresse_livraison && (dataItem.adresse_livraison.complement || '')} onChange={onChangeData} disabled={dataItem.is_work_order} />
                                            </FormField>
                                            <div className="field_group">
                                                <FormField label="text.code_postal">
                                                    <ValidationInput validation={validation}  name='adresse_livraison.cp' type='text' value={dataItem.adresse_livraison && (dataItem.adresse_livraison.cp || '')} onChange={onChangeData} disabled={dataItem.is_work_order} />
                                                </FormField>
                                                <FormField label="text.ville">
                                                    <ValidationInput validation={validation}  name='adresse_livraison.ville' type='text' value={dataItem.adresse_livraison && (dataItem.adresse_livraison.ville || '')} onChange={onChangeData} disabled={dataItem.is_work_order} />
                                                </FormField>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>

                    </div>
                </div>
            </view>
            <PanierExternePaymentForm
                dataItem={dataItem}
                onChangeData={onChangeData}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                submitData={submitData}
                color={color}
                universe={universe}
                validation={validation}
            />
        </view>
    </>;
}
