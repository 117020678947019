import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Spin } from 'antd';

import { Ajax } from 'components/Ajax';
import { ApiUrl } from 'project/Defines.js';

const api_url = process.env.REACT_APP_DEV_API_URL ?? process.env.REACT_APP_API_URL;
const api_app = process.env.REACT_APP_DEV_API_APP ?? process.env.REACT_APP_API_APP;

export default function Services(props) {
    const [outData, setOutData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [apiUrl, setApiUrl] = useState("");
    const [text, setText] = useState("");
    const [encrypt, setEncrypt] = useState("");
    const [output, setOutput] = useState("");
    const viewRef = useRef(null);
    const loading = <div style={{ "margin": "25% auto" }}><Spin size="large" /></div>;
    useEffect(() => { setApiUrl(api_url + api_app); }, [])

    const toolbar = [
        {
            name: "full process", file: "full_process", enabled: true
        },
        {
            name: "article", file: "ART", enabled: true
        },
        {
            name: "article_info", file: "ART_INFO", enabled: true
        },
        {
            name: "article_info_del", file: "ART_INFO_DEL", enabled: true
        },
        {
            name: "Cde_Ret", file: "CDE_RET", enabled: true
        },
        {
            name: "cdv", file: "CDV", enabled: true
        },
        //{
        //    name: "cdv_art", file: "cdv_art", enabled: false
        //},
        {
            name: "garantie_ret", file: "GARANTIE_RET", enabled: false
        },
        {
            name: "i18n_article", file: "ART_TRANS", enabled: true
        },
        {
            name: "i18n_produit", file: "PROD_TRANS", enabled: true
        },
        {
            name: "produit_add", file: "PROD", enabled: true
        },
        {
            name: "user", file: "USER", enabled: true
        },
        {
            name: "user_del", file: "USER_DEL", enabled: true
        },
    ];

    const doAction = useCallback((fileName, post) => {

        setIsLoading(true)
        Ajax.post({
            url: ApiUrl.ServiceDoAction,
            data: {
                fileName: fileName
            },
            success: function (response) {
                if (response && viewRef.current) {
                    setOutData(response.Data);
                    setMessage(response.Message);
                }
                setIsLoading(false)
            }
        })
    }, [])

    const doTestCrypt = useCallback(() => {

        setIsLoading(true)
        Ajax.post({
            url: ApiUrl.CommonTestCrypt,
            data: {
                text: text,
                method: "new3"
            },
            success: function (response) {
                if (response && viewRef.current) {
                    if (response.Data !== text) {
                        setEncrypt(response.Data);
                    } else {
                        setEncrypt(response.Message);
                    }
                }
                setIsLoading(false)
            }
        })
    }, [text])

    const doTestDecrypt = useCallback(() => {

        setIsLoading(true)
        Ajax.post({
            url: ApiUrl.CommonTestDeCrypt,
            data: {
                text: encrypt,
                method: "new3"
            },
            success: function (response) {
                if (response && viewRef.current) {
                    if (response.Data !== encrypt) {
                        setOutput(response.Data);
                    } else {
                        setOutput(response.Message);
                    }
                }
                setIsLoading(false)
            }
        })
    }, [encrypt])

    const doCheckApis = useCallback(() => {

        setIsLoading(true)
        Ajax.get({
            url: "/api/common/getapisdata",
            success: function (response) {
                setIsLoading(false)
            }
        })
    }, [])

    return <>
        <view ref={viewRef}>
            <toolbar scroll="">
                <wrap>
                    {
                        toolbar.map((item, index) => {
                            return (
                                <div
                                    key={index} // NOSONAR
                                    onClick={(e) => doAction(item.file, item.post)}
                                    className='button'
                                    {...(item.enabled || { disabled: true })}
                                >
                                    <text>{item.name}</text>
                                </div>)

                        })
                    }
                    <a
                        className='button'
                        href={apiUrl + ApiUrl.ServiceGetExportData + "?type=warranty_request&isTest=true"}
                    >
                        <text>Warranty request export</text>
                    </a>
                    <a
                        className='button'
                        href={apiUrl + "/api/print/svgtopng/"}
                    >
                        <text>SVG to PNG</text>
                    </a>

                    <div
                                    key="apisdata" // NOSONAR
                                    onClick={(e) => doCheckApis()}
                                    className='button'
                                >
                                    <text>apis data</text>
                                </div>
                </wrap>
            </toolbar>
            {
                1 > 2 &&
                <view>
                    <div className="section_group">

                        <div className="sections ">
                            <div className="form_fields">
                                <div className="data_label">text</div>
                                <input name="test" value={text} onChange={(event, callback) => setText(event.target.value)} />
                            </div>
                            <div className="form_fields">
                                <div className="data_label">encrypt</div>
                                <input name="encrypt" value={encrypt} />
                            </div>
                            <div className="form_fields">
                                <div className="data_label">output</div>
                                <input name="output" value={output} />
                            </div>
                        </div>
                    </div>
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div className="button" onClick={() => doTestCrypt()}><icon>plus</icon>encrypt</div>
                                <div className="button" onClick={() => doTestDecrypt()}><icon>minus</icon>decrypt</div>
                            </action>
                        </wrap>
                    </toolbar>
                </view>
            }
            <view scroll="" style={{ padding: "10px" }}>
                {
                    message && <p>{message}</p>
                }
                {
                    isLoading ?
                        loading
                        :
                        outData && outData.map((d, i) => {
                            return <p key={i} // NOSONAR
                            >{d}</p>;
                        })
                }
            </view>
        </view>
    </>;
}
