import React from 'react';
import { AppPages, Defines, UserRoles } from './Defines.js';
import moment from 'moment';
import store from 'store';

/**
 *a global helper object with utility functions
 */
export const Project = {
    getOfficeUrl: function(url){
        return null; //"https://view.officeapps.live.com/op/embed.aspx?src=" + (url.replace('localhost:44379','asssback.azurewebsites.net'))
    },

    getFullAddress: function (json) {
        return ("")
            .addItem(json.address1, ", ", true)
            .addItem(json.address2, ", ", true)
            .addItem(json.address3, ", ", true)
            .addItem(json.city, json.addressDelimiter || ", ", true)
            .addItem(json.state, ", ", true)
            .addItem(json.zipPostal, " ", true)
            .addItem(json.country, json.countryDelimiter || ", ", true);
    },

    getLocationAddress: function (data) {
        return Project.getFullAddress({
            address1: data.Address1,
            address2: data.Address2,
            city: data.City,
            state: data.State,
            zipPostal: data.ZipPostal,
            country: data.CountryName
        });
    },

    getContactInformation: function (json) {
        var s = ("") // NOSONAR
            .addItem(json.firstName, ", ", true)
            .addItem(json.lastName, " ", true);

        if (!String.isNullOrEmpty(json.title)) {
            s += " (" + json.title + ")";
        }

        return s.addItem([json.phone, json.email], ', ', true);
    },

    formatName: function (firstName, lastName) {
        var name = firstName; // NOSONAR
        if (lastName) {
            name += " " + lastName;
        }

        return name;
    },

    formatDuration: function (milliseconds, skipEmpty) {
        if (skipEmpty && !milliseconds) {
            return "";
        }

        if (!milliseconds) {
            return "0:00";
        }

        var seconds = Math.round(milliseconds / 1000), // NOSONAR
            hr = Math.floor(seconds / 3600),
            min = Math.floor((seconds - (hr * 3600)) / 60),
            sec = seconds - (hr * 3600) - (min * 60);

        return (hr > 0 ? hr + ":" : "") + (hr > 0 && min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
    },


    //new date/time
    dateTimeUtcToLocal: function(dt){
        return dt ? moment.utc(dt, Defines.Format.ServerDateTime).local() : null;
    },
    dateTimeLocalToUtc: function(dt){
        return dt ? moment(dt, Defines.Format.ServerDateTime).utc() : null;
    },

    // from UTC to Local (string)
    dateTimeUtcToLocalString: function(dt, includeTime = false){
        if (includeTime){
            return moment.utc(dt, Defines.Format.ServerDateTime).local().format(Defines.Format.LocalDateTime);
        }
        return dt ? moment.utc(dt, Defines.Format.ServerDateTime).local().format(Defines.Format.LocalDate) : null;
    },


    // from Local to UTC (string)
    dateTimeLocalToUtcString: function(dt, includeTime = false){
        if (includeTime) {
            return dt ? moment(dt, Defines.Format.LocalDateTime).utc().format(Defines.Format.ServerDateTime) : null;
        }
        return dt ? moment(dt, Defines.Format.LocalDate).format(Defines.Format.ServerDateTime) : null;
    },
    //



    formatToPrice(number) {
        return number.toLocaleString('fr-FR', { style: "currency", currency: "EUR" });
    },

    round2Decimals(number) {
        return Math.round(number * 100) / 100;
    },

    encode: str => {
        let buf = [];

        for (var i = str.length - 1; i >= 0; i--) { // NOSONAR
            buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
        }

        return buf.join('');
    },

    decode: str => {
        return str.replace(/&#(\d+);/g, function (match, dec) {
            return String.fromCharCode(dec);
        });
    },

    getPageUrl: function (page, routeParams, quaryParams) {
        if (!page) {
            return null;
        }
        let url = "/"; // NOSONAR
        switch (page.toLowerCase()) { // NOSONAR
            case AppPages.Home:
                url = "/"; // NOSONAR
                break;
            case AppPages.Login:
                url = "/login";
                break;
            case AppPages.Logout:
                url = "/logout";
                break;
            case AppPages.Template:
                url = "/template";
                break;

            case AppPages.Brand:
                url = "/brand";
                break;
            case AppPages.BrandDetails:
                url = "/branddetails";
                break;

            case AppPages.SalesNetwork:
                url = "/salesnetwork";
                break;
            case AppPages.SalesNetworkDetails:
                url = "/salesnetworkdetails";
                break;
            case AppPages.Universe:
                url = "/universe";
                break;
            case AppPages.UniverseDetails:
                url = "/universedetails";
                break;

            case AppPages.Products:
                url = "/products";
                break;
            case AppPages.ProductDetails:
                url = "/productdetails";
                break;

            case AppPages.BillOfMaterials:
                url = "/billofmaterials";
                break;
            case AppPages.BillOfMaterialDetails:
                url = "/billofmaterialdetails";
                break;
            case AppPages.BillOfMaterialExtract:
                url = "/billofmaterials/extract";
                break;

            case AppPages.SpareParts:
                url = "/spareparts";
                break;
            case AppPages.SparePartDetails:
                url = "/sparepartdetails";
                break;

            case AppPages.SparePartImagesUpload:
                url = "/spareparts/sparepartimages/upload";
                break;

            case AppPages.ProductParameters:
                url = "/productparameters";
                break;
            case AppPages.Requlations:
                url = "/requlations";
                break;
            case AppPages.Defects:
                url = "/defects";
                break;
            case AppPages.User:
                url = "/users";
                break
            case AppPages.UsersSiretChanges:
                url = "/user/userssiretchanges";
                break
            case AppPages.UserDetails:
                url = "/userdetails";
                break;
            case AppPages.SocieteUserDetails:
                url = "/societeuserdetails";
                break;

            case AppPages.Jobs:
                url = "/jobs";
                break;
            case AppPages.JobsWarrantyRequest:
                url = "/jobs/warrantyrequest";
                break;
            case AppPages.JobsWarrantyRequestDetails:
                url = "/jobs/warrantyrequestdetails";
                break;
            case AppPages.JobsSalesOrder:
                url = "/jobs/salesorder";
                break;
            case AppPages.JobsSalesOrderDetails:
                url = "/jobs/salesorderdetails";
                break;
            case AppPages.JobsDevis:
                url = "/jobs/devis";
                break;

            case AppPages.Administration:
                url = "/administration";
                break;
            case AppPages.Administrators:
                url = "/administration/administrators";
                break;
            case AppPages.AdministratorsDetails:
                url = "/administration/administratorsdetails";
                break;
            case AppPages.AllowedIP:
                url = "/administration/allowedip";
                break;
            case AppPages.AllowedIPDetails:
                url = "/administration/allowedipdetails";
                break;
            case AppPages.BankCard:
                url = "/administration/bankcard";
                break;
            case AppPages.BankCardDetails:
                url = "/administration/bankcarddetails";
                break;
            case AppPages.ReseauConfigCBS:
                url = "/administration/reseauconfigcbs";
                break;
            case AppPages.ReseauConfigCBSDetails:
                url = "/administration/reseauconfigcbsdetails";
                break;
            case AppPages.APIAccess:
                url = "/administration/apiaccess";
                break;
            case AppPages.APIAccessDetails:
                url = "/administration/apiaccessdetails";
                break;
            case AppPages.Applications:
                url = "/administration/applications";
                break;
            case AppPages.ApplicationsDetails:
                url = "/administration/applicationsdetails";
                break;
            case AppPages.TVA:
                url = "/administration/tva";
                break;
            case AppPages.TVADetails:
                url = "/administration/tvadetails";
                break;
            case AppPages.History:
                url = "/administration/history";
                break;
            case AppPages.HistoryDetails:
                url = "/administration/historydetails";
                break;
            case AppPages.ConnectivityBlocking:
                url = "/administration/connectivityblocking";
                break;
            case AppPages.Skills:
                url = "/administration/skills";
                break;
            case AppPages.SkillsDetails:
                url = "/skillsdetails";
                break;
            case AppPages.Fees:
                url = "/administration/fees";
                break;
            case AppPages.FeeDetails:
                url = "/administration/feedetails";
                break;
            case AppPages.DefectCode:
                url = "/administration/defectcode";
                break;
            case AppPages.DefectCodeDetails:
                url = "/administration/defectcodedetails";
                break;
            case AppPages.Coverage:
                url = "/administration/coverage";
                break;
            case AppPages.CoverageDetails:
                url = "/administration/coveragedetails";
                break;
            case AppPages.Settings:
                url = "/administration/settings";
                break;
            case AppPages.SSO:
                url = "/administration/sso";
                break;
            case AppPages.SSODetails:
                url = "/administration/ssodetails";
                break;
            case AppPages.Maintenance:
                url = "/administration/maintenance";
                break;
            case AppPages.MaintenanceDetails:
                url = "/administration/maintenancedetails";
                break;

            case AppPages.Setup:
                url = "/setup";
                break;
            case AppPages.SetupArticleTypes:
                url = "/setup/articletypes";
                break;
            case AppPages.SetupArticleTypeDetails:
                url = "/setup/articletypedetails";
                break;
            case AppPages.SetupClientTypes:
                url = "/setup/clienttypes";
                break;
            case AppPages.SetupClientTypeDetails:
                url = "/setup/clienttypedetails";
                break;
            case AppPages.SetupNormes:
                url = "/setup/normes";
                break;
            case AppPages.SetupNormDetails:
                url = "/setup/normdetails";
                break;
            case AppPages.SetupProductDefect:
                url = "/setup/productdefects";
                break;
            case AppPages.SetupProductDefectDetails:
                url = "/setup/productdefectdetails";
                break;
            case AppPages.SetupProductParameter:
                url = "/setup/productparameter";
                break;
            case AppPages.SetupProductParameterDetails:
                url = "/setup/productparameterdetails";
                break;
            case AppPages.SetupVideo:
                url = "/setup/video";
                break;
            case AppPages.SetupVideoDetails:
                url = "/setup/videodetails";
                break;
            case AppPages.SetupWarrantyTypes:
                url = "/setup/warrantytypes";
                break;
            case AppPages.SetupWarrantyTypeDetails:
                url = "/setup/warrantytypedetails";
                break;
            case AppPages.SetupCountryGrouping:
                url = "/setup/countrygrouping";
                break;
            case AppPages.SetupCountryGroupingDetails:
                url = "/setup/countrygroupingdetails";
                break;
            case AppPages.SetupCountries:
                url = "/setup/country";
                break;
            case AppPages.SetupCountryDetails:
                url = "/setup/countrydetails";
                break;
            case AppPages.SetupRechercheNoSerie:
                url = "/setup/recherchenoserie";
                break;
            case AppPages.SetupRechercheNoSerieDetails:
                url = "/setup/recherchenoseriedetails";
                break;
            case AppPages.SetupTranslation:
                url = "/setup/translation";
                break;
            case AppPages.SetupLanguages:
                url = "/setup/languages";
                break;
            case AppPages.SetupLanguageDetails:
                url = "/setup/languagesdetails";
                break;

            case AppPages.Charts:
                url = "/charts";
                break;
            
            case AppPages.DocumentView:
                url = "/documentview";
                break;

            case AppPages.Process:
                url = "/process";
                break;
            case AppPages.ProcessDetails:
                url = "/processdetails";
                break;


            default:
                url = "/"; // NOSONAR
                break;
        }

        if (routeParams) {
            url += "/" + (Array.isArray(routeParams) ? routeParams.join('/') : routeParams);
        }

        if (quaryParams) {
            var p = []; // NOSONAR
            for (var key in quaryParams) { p.push(key + "=" + quaryParams[key]) }; // NOSONAR
            url += "?" + p.join("&");
        }

        return url;
    },

    getPages: function () {
        return ["Login", "Logout"];
    },
    hasRoleAccess: function (userRole, appPage) {
        const checkingRoles = Project.getPageRoles(appPage);

        if (!checkingRoles) {
            return true;
        }
        switch (userRole) {
            case UserRoles.SUPERADMIN:
                return true;
            case UserRoles.ADMIN:
                return checkingRoles.some(cr => cr === UserRoles.ADMIN || cr === UserRoles.SATC || cr === UserRoles.DISPATCHER);
            case UserRoles.SATC:
                return checkingRoles.includes(UserRoles.SATC);
            case UserRoles.DISPATCHER:
                return checkingRoles.includes(UserRoles.DISPATCHER);
            default:
                return false;

        }
    },
    getPageRoles: function (appPage) {
        switch (appPage) {
            case AppPages.User:
            case AppPages.Users:
                return [UserRoles.SATC, UserRoles.DISPATCHER];

            case AppPages.ConnectivityBlocking:
                return [UserRoles.SATC, UserRoles.ADMIN, UserRoles.SUPERADMIN];


            case AppPages.Requlations:
            case AppPages.Defects:
            case AppPages.UserDetails:
            case AppPages.Jobs:
            case AppPages.JobsWarrantyRequest:
            case AppPages.JobsWarrantyRequestDetails:
            case AppPages.JobsSalesOrder:
            case AppPages.JobsSalesOrderDetails:
            case AppPages.JobsDevis:
            case AppPages.SetupProductDefect:
            case AppPages.SetupProductDefectDetails:
            case AppPages.SetupVideo:
            case AppPages.SetupVideoDetails:
                return [UserRoles.SATC];

            case AppPages.Skills:
            case AppPages.SkillsDetails:
            case AppPages.Fees:
            case AppPages.FeeDetails:
            case AppPages.DefectCode:
            case AppPages.DefectCodeDetails:
            case AppPages.Universe:
            case AppPages.UniverseDetails:
                return [UserRoles.DISPATCHER];

            case AppPages.Brand:
            case AppPages.BrandDetails:
            case AppPages.CreditCardPaymentsDetails:
            case AppPages.Products:
            case AppPages.ProductDetails:
            case AppPages.ProductParameters:
            case AppPages.BillOfMaterials:
            case AppPages.BillOfMaterialDetails:
            case AppPages.BillOfMaterialExtract:
            case AppPages.SpareParts:
            case AppPages.SparePartDetails:
            case AppPages.TVA:
            case AppPages.TVADetails:
            case AppPages.SetupProductParameter:
            case AppPages.SetupProductParameterDetails:
            case AppPages.SetupNormes:
            case AppPages.SetupNormDetails:
            case AppPages.SetupCountries:
            case AppPages.SetupCountryDetails:
            case AppPages.SetupCountryGrouping:
            case AppPages.SetupCountryGroupingDetails:
            case AppPages.SetupArticleTypes:
            case AppPages.SetupArticleTypeDetails:
            case AppPages.SetupRechercheNoSerie:
            case AppPages.SetupRechercheNoSerieDetails:
            case AppPages.SetupTranslation:
                return [UserRoles.ADMIN];
            case AppPages.Administration:
            case AppPages.Administrators:
            case AppPages.AdministratorsDetails:
            case AppPages.AllowedIP:
            case AppPages.AllowedIPDetails:
            case AppPages.BankCard:
            case AppPages.BankCardDetails:
            case AppPages.ReseauConfigCBS:
            case AppPages.ReseauConfigCBSDetails:
            case AppPages.APIAccess:
            case AppPages.APIAccessDetails:
            case AppPages.Applications:
            case AppPages.ApplicationsDetails:
            case AppPages.History:
            case AppPages.HistoryDetails:
            case AppPages.SetupClientTypes:
            case AppPages.SetupClientTypeDetails:
            case AppPages.SetupWarrantyTypes:
            case AppPages.SetupWarrantyTypeDetails:
            case AppPages.SalesNetwork:
            case AppPages.SalesNetworkDetails:
            case AppPages.SSO:
            case AppPages.SSODetails:
            case AppPages.Maintenance:
            case AppPages.MaintenanceDetails:
            case AppPages.Process:
            case AppPages.ProcessDetails:
            case AppPages.ProcessLog:
            case AppPages.ProcessLogDetails:
            case AppPages.ProcessLogDetail:
            case AppPages.ProcessLogDetailDetails:
                return [UserRoles.SUPERADMIN];
            case AppPages.NotFound:
            case AppPages.AccessDenied:
            case AppPages.ExternalCard:
            case AppPages.Home:
            case AppPages.Login:
            case AppPages.Logout:
            case AppPages.Register:
            case AppPages.Forgot:
            case AppPages.Template:
                return null;
            default:
                return [UserRoles.SUPERADMIN];
        }
    },
    skipHeader: function (location) {
        const skipping_pages = [AppPages.Login, AppPages.Logout, AppPages.ExternalCard, AppPages.DocumentView];
        return skipping_pages.some(sp => location.includes(sp));
    },

    restrictBrowser: function (location) {
        const skipping_pages = [AppPages.ExternalCard];

        return !skipping_pages.some(sp => location.includes(sp));
    },

    appendToUrl: (url, key, value) => {
        if (url.indexOf(key) === -1 && value && value !== 'undefined'){
            if (url.indexOf('?') !== -1){
                url += "&" 
            } else {
                url += "?" 
            }
    
            url += key + "=" + value
        }
        return url;
    },

    luhnAlgo: function (number, pair, modulo = 10) {
        let somme = 0;
        let tmp;
        for (let cpt = 0; cpt < number.length; cpt++) {
            if ((cpt % 2) === (pair ? 0 : 1)) {
                tmp = number.charAt(cpt) * 2;
                if (tmp > 9) tmp -= 9;
            }
            else tmp = number.charAt(cpt);

            somme += parseInt(tmp);
        }
        return (somme % modulo) === 0;
    },

    isValideSiret: function (siret) {
        return true;
    },

    isValideSiren: function (siren) {
        let valid = false;
        if ((siren.length !== 9) || (isNaN(siren))) valid = false; // NOSONAR
        else {
            valid = this.luhnAlgo(siren, false)
        }
        return valid;
    },

    getColorAction: function (text) {
        switch(text) {
            case "Add":
                return <badge new="">{text}</badge>;
            case "Update":
                return <badge private="">{text}</badge>;
            case "Error":
                return <badge error="">{text}</badge>;
            case "Warning":
                return <badge error="">{text}</badge>;
            default:
                return <></>
        }
    },

    getRecordActionClass: function (record) {
        switch (record.Action) {
            case "Add":
                return "blue";
            case "Update":
                return "green";
            case "Error":
                return "red";
            case "Warning":
                return "yellow";
            default:
                return "";
        }
    },
    getImportActionColumn: (columnType) => {
        return {
            text: 'text.action',
            field: 'Action',
            render: (text, record) => {
                return (
                    <wrap>
                        <group compact="">
                            {Project.getColorAction(text)}
                        </group>
                    </wrap>
                );
            },
            type: columnType,
            width: 100,
            fixed: 'right'
        };
    },
    getImportMessageColumn: (columnType) => {
        return {
            text: 'text.message',
            field: 'Message',
            type: columnType,
            width: 300,
            fixed: 'right'
        };
    },

    refreshUserToken: (successCallback)=> {
        const userToken = Project.getLocalStorage("userToken");
        if (userToken) {
            Project.setLocalStorage("isLoadingUserToken", true, 30);
            const callback = `${window.location.origin}/wso/callback`;

            let fd = "";
            fd += "grant_type=refresh_token";
            fd += "&redirect_uri=" + callback;
            fd += "&refresh_token=" + userToken.refresh_token;
            let headers = { "Content-Type": "application/x-www-form-urlencoded" };
            const fetchOptions = { 
                method: "POST",
                body: fd
            };
            let params = {
                ...fetchOptions, 
                headers: headers
            };

            fetch(`${process.env.REACT_APP_API_TOKEN_URL ?? window.location.origin}/api/token`, params)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response && response.access_token){ // NOSONAR
                    Project.setLocalStorage("userToken", response, response.expires_in - 600);
                    //dispatch(changeUserToken(response.access_token)) // NOSONAR
                    Project.setLocalStorage("isLoadingUserToken", false, 30);
                    successCallback && successCallback(response);

                }
                Project.setLocalStorage("isLoadingUserToken", false, 30);
            })
            .catch((error) =>{
                console.log(error);
            });
        }
            

    },

    /**
     * @param {string} keyName - A key to identify the value.
     * @param {any} keyValue - A value associated with the key.
     * @param {number} ttl- Time to live in seconds.
     */
    setLocalStorage: (keyName, keyValue, ttl) => {
        const data = {
            value: keyValue,                  // store the value within this object
            ttl: Date.now() + ((ttl ?? 9999999) * 1000),   // store the TTL (time to live)
        }
    
        // store data in LocalStorage 
        store.set(keyName, data);
    },

    /**
     * @param {string} keyName - A key to identify the data.
     * @param {any|null} callback - callback function if expire value.
     * @returns {any|null} returns the value associated with the key if its exists and is not expired. Returns `null` otherwise
     */
    getLocalStorage: (keyName, callback) => {
        const data = store.get(keyName);
        if (!data) {     // if no value exists associated with the key, return null
            return null;
        }
    
        // If TTL has expired, remove the data from localStorage and return null
        if (!data.ttl || Date.now() > data.ttl) {
            store.remove(keyName);
            if (callback) callback(data.value);
            return null;
        }
    
        // return data if not expired
        return data.value;
    }
}