/**
 * the formats for different types of data
 */
export const Defines = {
    Format: {
        ServerDateTime: "YYYY-MM-DD[T]HH:mm:ss",

        LocalDate: "DD/MM/YYYY", //"MM/dd/yyyy",
        LocalDateTime: "DD/MM/YYYY HH:mm",

        //Time: "HH:mm",
        FullTime: "HH:mm:ss",
    },
    Messages: {
        Yes: "Yes",
        No: "No",
    }
};

/**
 * shorthands to get page urls
 */
export const AppPages = {
    Home: "home",

    Login: "login",
    Logout: "logout",
    Register: "register",
    Forgot: "forgot",

    Brand: "brand",
    BrandDetails: "branddetails",
    User: "user",
    UserDetails: "userdetails",
    SocieteUserDetails: "societeuserdetails",
    SalesNetwork: "salesnetwork",
    SalesNetworkDetails: "salesnetworkdetails",
    CreditCardPaymentsDetails: "creditcardpaymentsdetails",
    Universe: "universe",
    UniverseDetails: "universedetails",
    Products: "products",
    ProductDetails: "productdetails",
    BillOfMaterials: "billofmaterials",
    BillOfMaterialDetails: "billofmaterialdetails",
    BillOfMaterialExtract: "billofmaterialextract",
    SpareParts: "spareparts",
    SparePartDetails: "sparepartdetails",
    SparePartImagesUpload: "sparepartimagesupload",
    ProductParameters: "productparameters",
    Requlations: "requlations",
    Defects: "defects",
    Users: "users",
    UsersSiretChanges: "userssiretchanges",
    Jobs: "jobs",
    JobsWarrantyRequest: "jobswarrantyrequest",
    JobsWarrantyRequestDetails: "jobswarrantyrequestdetails",
    JobsSalesOrder: "jobssalesorder",
    JobsSalesOrderDetails: "jobssalesorderdetails",
    JobsDevis: "jobsdevis",

    Setup: "setup",
    SetupProductParameter: "setupproductparameter",
    SetupProductParameterDetails: "setupproductparameterdetails",
    SetupClientTypes: "setupclienttypes",
    SetupClientTypeDetails: "setupclienttypedetails",
    SetupWarrantyTypes: "setupwarrantytypes",
    SetupWarrantyTypeDetails: "setupwarrantytypedetails",
    SetupProductDefect: "setupproductdefect",
    SetupProductDefectDetails: "setupproductdefectdetails",
    SetupArticleTypes: "setuparticletypes",
    SetupArticleTypeDetails: "setuparticlestypedetails",
    SetupVideo: "setupvideo",
    SetupVideoDetails: "setupvideodetails",
    SetupNormes: "setupnormes",
    SetupNormDetails: "setupnormdetails",
    SetupCountryGrouping: "setupcountrygrouping",
    SetupCountryGroupingDetails: "setupcountrygroupingdetails",
    SetupCountries: "setupcountries",
    SetupCountryDetails: "setupcountrydetails",
    SetupRechercheNoSerie: "setuprecherchenoserie",
    SetupRechercheNoSerieDetails: "setuprecherchenoseriedetails",
    SetupTranslation: "setuptranslation",
    SetupLanguages: "setuplanguages",
    SetupLanguageDetails: "setuplanguagedetails",

    Administration: "administration",
    Administrators: "administrators",
    AdministratorsDetails: "administratorsdetails",
    AllowedIP: "allowedip",
    AllowedIPDetails: "allowedipdetails",
    BankCard: "bankcard",
    BankCardDetails: "bankcarddetails",
    ReseauConfigCBS: "reseauconfigcbs",
    ReseauConfigCBSDetails: "reseauconfigcbsdetails",
    APIAccess: "apiaccess",
    APIAccessDetails: "apiaccessdetails",
    Applications: "applications",
    ApplicationsDetails: "applicationsdetails",
    TVA: "tva",
    TVADetails: "tvadetails",
    History: "history",
    HistoryDetails: "historydetails",
    ConnectivityBlocking: "connectivityblocking",
    Skills: "skills",
    SkillsDetails: "skillsdetails",
    Fees: "fees",
    FeeDetails: "feedetails",
    DefectCode: "defectcode",
    DefectCodeDetails: "defectcodedetails",
    Coverage: "coverage",
    CoverageDetails: "coveragedetails",
    Settings: "settings",

    Process: "process",
    ProcessDetails: "processdetails",
    ProcessLog: "processlog",
    ProcessLogDetails: "processlogdetails",
    ProcessLogDetail: "processlogdetail",
    ProcessLogDetailDetails: "processlogdetaildetails",
    Charts: "charts",
    DocumentView: "documentview",
    SSO: "sso",
    SSODetails: "ssodetails",
    Maintenance: "maintenance",
    MaintenanceDetails: "maintenancedetails",

    NotFound: "notfound",
    AccessDenied: "accessdenied",
    ExternalCard: "panier_extern",

    Template: "template"
}

export const StoreKeys = {
    UserToken: "USER-TOKEN",
    AppToken: "APP-TOKEN",
    UserName: "userName",
    LoggedIn: "loggedIn",
    ListStates: "listStates",
    Culture: "culture",
    Role: "role",
    Settings: "settings",
    ExpireTime: "exptime"

}

/**
 * the url endings for each action of api
 */
export const ApiUrl = {
    Login: "/user/login", //"/api/account/login",
    LoginByCode: "/api/account/logincode",
    GetUser: "/api/account/getuser", //"/api/account/login",
    Logout: "/api/account/logout",
    Register: "/api/account/register",
    Forgot: "/api/account/forgot",
    Connect: "/api/wso/ssoconnect",
    WarrantyRequestActions: "/api/warrantyrequest",
    BrandList: "/api/brands/getlistdata",
    BrandUpdate: "/api/brands/updateformdata",
    BrandLoad: "/api/brands/resetformdata",
    BrandsDelete: "/api/brands/deleteformdata",
    SalesNetworkList: "/api/salesnetwork/getlistdata",
    SalesNetworkUpdate: "/api/salesnetwork/updateformdata",
    SalesNetworkDelete: "/api/salesnetwork/deleteformdata",
    SalesNetworkLoad: "/api/salesnetwork/resetformdata",
    SalesNetworkUniversList: "/api/universesitesreseaux/getlistdata",
    SalesOrderList: "/api/salesorder/getlistdata",
    SalesOrderUpdate: "/api/salesorder/updateformdata",
    SalesOrderDelete: "/api/salesorder/deleteformdata",
    SalesOrderLoad: "/api/salesorder/resetformdata",
    DevisList: "/api/devis/getlistdata",
    DevisUpdate: "/api/devis/updateformdata",
    DevisDelete: "/api/devis/deleteformdata",
    DevisLoad: "/api/devis/resetformdata",
    CreditCardPaymentsList: "/api/salesnetworkpayments/getlistdata",
    CreditCardPaymentsUpdate: "/api/salesnetworkpayments/updateformdata",
    CreditCardPaymentsDelete: "/api/salesnetworkpayments/deleteformdata",
    CreditCardPaymentsLoad: "/api/salesnetworkpayments/resetformdata",
    BillOfMaterialList: "/api/billofmaterial/getlistdata",
    BillOfMaterialLoad: "/api/billofmaterial/resetformdata",
    BillOfMaterialUpdate: "/api/billofmaterial/updateformdata",
    BillOfMaterialDelete: "/api/billofmaterial/deleteformdata",
    BillOfMaterialProductAdd: "/api/billofmaterial/addbillofmaterialproducts",
    BillOfMaterialDuplicate: "/api/billofmaterial/duplicatebillofmaterial",
    ArticleList: "/api/article/getlistdata",
    ArticleLoad: "/api/article/resetformdata",
    ArticleUpdate: "/api/article/updateformdata",
    ArticleDelete: "/api/article/deleteformdata",
    ArticleSubstitute: "/api/article/substitute",
    ArticleProductList: "/api/articleproduct/getlistdata",
    ArticleBillOfMaterialList: "/api/articlebillofmaterial/getlistdata",
    ArticleBillOfMaterialLoad: "/api/articlebillofmaterial/resetformdata",
    ArticleBillOfMaterialUpdate: "/api/articlebillofmaterial/updateformdata",
    ArticleBillOfMaterialAdd: "/api/articlebillofmaterial/addarticlebillofmaterials",
    ArticleBillOfMaterialDelete: "/api/articlebillofmaterial/deleteformdata",
    AccessoryProductList: "/api/accessoryproduct/getlistdata",
    AccessoryProductUpdate: "/api/accessoryproduct/updateformdata",
    AccessoryProductAdd: "/api/accessoryproduct/addproductaccessories",
    AccessoryProductDelete: "/api/accessoryproduct/deleterelationdata",
    ParameterProductList: "/api/parameterproduct/getlistdata",
    ParameterProductLoad: "/api/parameterproduct/resetformdata",
    ParameterProductUpdate: "/api/parameterproduct/updateformdata",
    ParameterProductDelete: "/api/parameterproduct/deleteformdata",
    ParameterList: "/api/parameter/getlistdata",
    ParameterLoad: "/api/parameter/resetformdata",
    ParameterUpdate: "/api/parameter/updateformdata",
    ParameterDelete: "/api/parameter/deleteformdata",
    ProductSalesNetworkList: "/api/productsalesnetwork/getlistdata",
    ProductSalesNetworkLoad: "/api/productsalesnetwork/resetformdata",
    ProductSalesNetworkUpdate: "/api/productsalesnetwork/updateformdata",
    ProductSalesNetworkDelete: "/api/productsalesnetwork/deleterelationdata",
    ProductsList: "/api/product/getlistdata",
    ProductsUpdate: "/api/product/updateformdata",
    RemoveBOMProduct: "/api/product/removebomproduct",
    ProductsSavCommentaireUpdate: "/api/product/savcommentaireupdate",
    ProductsDelete: "/api/product/deleteformdata",
    ProductLoad: "/api/product/resetformdata",
    ProductDuplicate: "/api/product/duplicateproduct",
    UniverseList: "/api/univers/getlistdata",
    UniverseLoad: "/api/univers/resetformdata",
    UniverseUpdate: "/api/univers/updateformdata",
    UniverseDelete: "/api/univers/deleteformdata",
    SchemaList: "/api/schema/getlistdata",
    SchemaLoad: "/api/schema/resetformdata",
    SchemaUpdate: "/api/schema/updateformdata",
    SchemaDelete: "/api/schema/deleteformdata",
    SchemaChangeLineNumbers: "/api/schema/changelinenumbers",
    ZoneAndArticleList: "/api/zoneandarticle/getlistdata",
    ZoneList: "/api/zone/getlistdata",
    ZoneLoad: "/api/zone/resetformdata",
    ZoneUpdate: "/api/zone/updateformdata",
    ZoneDelete: "/api/zone/deleteformdata",
    ZoneDuplicate: "/api/zone/duplicate",
    ZoneAddArticles: "/api/zone/addarticles",
    ZoneDeleteArticle: "/api/articlezone/deleterelationdata", //"/api/Zone/DeleteArticle",
    ArticleZoneList: "/api/articlezone/getlistdata",
    ArticleZoneLoad: "/api/articlezone/resetformdata",
    TranslationList: "/api/translations/getlistdata",
    TranslationsUpdate: "/api/translations/updatetranslations",
    FamiliesList: "/api/families/getlistdata",
    FamiliesLoad: "/api/families/resetformdata",
    FamiliesUpdate: "/api/families/updateformdata",
    FamiliesParentChange: "/api/families/changeparentandlinenumbers",
    FamiliesDelete: "/api/families/deleteformdata",
    FamiliesBackup: "/api/families/backup",
    FamiliesRestore: "/api/families/restore",
    FamiliesNodeLoad: "/api/families/getfamily",
    FamilyBackupList: "/api/familybackup/getlistdata",
    FamilyNormeList: "/api/familynorme/getlistdata",
    FamilyNormeUpdate: "/api/familynorme/updatefamilynormes",
    FamilyProductAdd: "/api/familyproduct/addproducts",
    FamilyProductList: "/api/familyproduct/getlistdata",
    FamilyProductDelete: "/api/familyproduct/deleterelationdata",
    FamilyProductPayUpdate: "/api/familyproduct/updatepaysproduit",
    FamilyEvolutionList: "/api/familyevolution/getlistdata",
    FamilyEvolutionLoad: "/api/familyevolution/resetformdata",
    FamilyEvolutionUpdate: "/api/familyevolution/updateformdata",
    FamilyEvolutionDelete: "/api/familyevolution/deleteformdata",
    FamilyDefectList: "/api/familydefect/getlistdata",
    FamilyDefectLoad: "/api/familydefect/resetformdata",
    FamilyDefectUpdate: "/api/familydefect/updateformdata",
    FamilyDefectDelete: "/api/familydefect/deleteformdata",
    FamilyVideoAdd: "/api/familyvideo/addvideos",
    FamilyVideoList: "/api/familyvideo/getlistdata",
    FamilyVideoDelete: "/api/familyvideo/deleterelationdata",
    FamilyCustomerWarrantyList: "/api/familycustomerwarranty/getlistdata",
    FamilyCustomerWarrantyUpdate: "/api/familycustomerwarranty/updatefamilycustomerwarranty",
    FamilyProductWarrantyList: "/api/familyproductwarranty/getlistdata",
    FamilyProductWarrantyUpdate: "/api/familyproductwarranty/updatefamilyproductwarranty",
    FamilyInfosList: "/api/familyinfos/getlistdata",
    FamilyInfosLoad: "/api/familyinfos/resetformdata",
    FamilyInfosUpdate: "/api/familyinfos/updateformdata",
    FamilyInfosDelete: "/api/familyinfos/deleteformdata",
    SavInfoLoad: "/api/savinfo/resetformdata",
    SavInfoUpdate: "/api/savinfo/updateformdata",
    SavInfoDiagnosticsList: "/api/savinfodiagnostics/getlistdata",
    SavInfoDiagnosticsLoad: "/api/savinfodiagnostics/resetformdata",
    SavInfoDiagnosticsUpdate: "/api/savinfodiagnostics/updateformdata",
    SavInfoDiagnosticsDelete: "/api/savinfodiagnostics/deleteformdata",
    SavInfoDiagnosticsChangeLineNumbers: "/api/savinfodiagnostics/changelinenumbers",
    CountryGroupList: "/api/countrygroup/getlistdata",
    CountryGroupDelete: "/api/countrygroup/deleteformdata",
    CountryGroupLoad: "/api/countrygroup/resetformdata",
    CountryGroupUpdate: "/api/countrygroup/updateformdata",
    VideosVimeoList: "/api/videosvimeo/getlistdata",
    VideosVimeoLoad: "/api/videosvimeo/resetformdata",
    VideosVimeoUpdate: "/api/videosvimeo/updateformdata",
    VideosVimeoDelete: "/api/videosvimeo/deleteformdata",
    NormsList: "/api/norms/getlistdata",
    NormsLoad: "/api/norms/resetformdata",
    NormsUpdate: "/api/norms/updateformdata",
    NormsDelete: "/api/norms/deleteformdata",
    ValueList: "/api/valuelist/getlist",
    ClientTypeList: "/api/clienttype/getlistdata",
    ClientTypeLoad: "/api/clienttype/resetformdata",
    ClientTypeUpdate: "/api/clienttype/updateformdata",
    ClientTypeDelete: "/api/clienttype/deleteformdata",

    CountryList: "/api/country/getlistdata",
    CountryDelete: "/api/country/deleteformdata",
    CountryUpdate: "/api/country/updateformdata",
    CountryLoad: "/api/country/resetformdata",

    LanguageList: "/api/language/getlistdata",
    LanguageDelete: "/api/language/deleteformdata",
    LanguageUpdate: "/api/language/updateformdata",
    LanguageLoad: "/api/language/resetformdata",

    RechercheNoSerieList: "/api/recherchenoserie/getlistdata",
    RechercheNoSerieDelete: "/api/recherchenoserie/deleteformdata",
    RechercheNoSerieUpdate: "/api/recherchenoserie/updateformdata",
    RechercheNoSerieLoad: "/api/recherchenoserie/resetformdata",

    BlocageConnectivitesList: "/api/blocageconnectivites/getlistdata",
    BlocageConnectivitesAdd: "/api/blocageconnectivites/addselected",
    BlocageConnectivitesRemove: "/api/blocageconnectivites/removeselected",

    DefectList: "/api/defect/getlistdata",
    DefectLoad: "/api/defect/resetformdata",
    DefectUpdate: "/api/defect/updateformdata",
    DefectDelete: "/api/defect/deleteformdata",
    WarrantyTypeList: "/api/warrantytype/getlistdata",
    WarrantyTypeLoad: "/api/warrantytype/resetformdata",
    WarrantyTypeUpdate: "/api/warrantytype/updateformdata",
    WarrantyTypeDelete: "/api/warrantytype/deleteformdata",
    ArticleTypeList: "/api/articletype/getlistdata",
    ArticleTypeLoad: "/api/articletype/resetformdata",
    ArticleTypeUpdate: "/api/articletype/updateformdata",
    ArticleTypeDelete: "/api/articletype/deleteformdata",
    ServiceDoAction: "/api/services/doaction",
    ServiceGetExportData: "/api/services/getexportdata",
    ProcessDoAction: "/api/process/doaction",
    ProcessList: "/api/process/getlistdata",
    ProcessUpdate: "/api/process/updateformdata",
    ProcessLoad: "/api/process/resetformdata",
    ProcessDelete: "/api/process/deleteformdata",
    ProcessLogList: "/api/processlog/getlistdata",
    ProcessLogUpdate: "/api/processlog/updateformdata",
    ProcessLogLoad: "/api/processlog/resetformdata",
    ProcessLogDelete: "/api/processlog/deleteformdata",
    ProcessLogDetailList: "/api/processlogdetail/getlistdata",
    ProcessLogDetailUpdate: "/api/processlogdetail/updateformdata",
    ProcessLogDetailLoad: "/api/processlogdetail/resetformdata",
    ProcessLogDetailDelete: "/api/processlogdetail/deleteformdata",
    CommonTestCrypt: "/api/common/testcrypt",
    CommonTestDeCrypt: "/api/common/testdecrypt",
    UserList: "/api/societe/getlistdata",
    UserUpdate: "/api/societe/updatesociete",
    UserLoad: "/api/societe/resetformdata",
    UserDelete: "/api/societe/deleteformdata",
    ConnectAsUser: "/api/user/connectasuser",
    SocieteUserList: "/api/user/getlistdata",
    SocieteUserUpdate: "/api/user/updateuser",
    SocieteUserLoad: "/api/user/resetformdata",
    SocieteUsersDelete: "/api/user/deleteformdata",
    SocieteUserCheckEmail: "/api/user/checkemail",
    SocieteUserCheckIsUserInWso: "/api/user/checkisuserinwso",
    SocieteVerifySiret: "/api/societe/verifysiret", //{"siret": "0000000"}
    SocieteChangeSiret: "/api/societe/changesiret", //{ "old_siret": "0000000", "new_siret": "0000000", delete: false}
    SocieteSalesNetworkList: "/api/salesnetworksociete/getlistdata",
    SocieteSalesNetworkUpdate: "/api/salesnetworksociete/updateformdata",
    SocieteSalesNetworkLoad: "/api/salesnetworksociete/resetformdata",
    SocieteSalesNetworksDelete: "/api/salesnetworksociete/deleteformdata",
    SocieteClubStatusList: "/api/societeclubstatus/getlistdata",
    SocieteClubStatusUpdate: "/api/societeclubstatus/updateformdata",
    SocieteClubStatusLoad: "/api/societeclubstatus/resetformdata",
    SocieteClubStatusDelete: "/api/societeclubstatus/deleteformdata",
    SystemUserList: "/api/systemuser/getlistdata",
    SystemUserUpdate: "/api/systemuser/updateformdata",
    SystemUserLoad: "/api/systemuser/resetformdata",
    SystemUserDelete: "/api/systemuser/deleteformdata",
    AuthorizationAccessList: "/api/authorizationaccess/getlistdata",
    AuthorizationAccessUpdate: "/api/authorizationaccess/updateformdata",
    AuthorizationAccessLoad: "/api/authorizationaccess/resetformdata",
    AuthorizationAccessDelete: "/api/authorizationaccess/deleteformdata",
    ApiKeyList: "/api/apikey/getlistdata",
    ApiKeyUpdate: "/api/apikey/updateformdata",
    ApiKeyLoad: "/api/apikey/resetformdata",
    ApiKeyDelete: "/api/apikey/deleteformdata",
    CGVList: "/api/cgv/getlistdata",
    CGVUpdate: "/api/cgv/updateformdata",
    CGVLoad: "/api/cgv/resetformdata",
    CGVDelete: "/api/cgv/deleteformdata",
    ReseauConfigCBSList: "/api/reseauconfigcbs/getlistdata",
    ReseauConfigCBSUpdate: "/api/reseauconfigcbs/updateformdata",
    ReseauConfigCBSLoad: "/api/reseauconfigcbs/resetformdata",
    ReseauConfigCBSDelete: "/api/reseauconfigcbs/deleteformdata",
    SalesNetworkTVAList: "/api/salesnetworktva/getlistdata",
    SalesNetworkTVAUpdate: "/api/salesnetworktva/updateformdata",
    SalesNetworkTVALoad: "/api/salesnetworktva/resetformdata",
    SalesNetworkTVADelete: "/api/salesnetworktva/deleteformdata",
    HistoryList: "/api/history/getlistdata",
    HistoryUpdate: "/api/history/updateformdata",
    HistoryLoad: "/api/history/resetformdata",
    HistoryDelete: "/api/history/deleteformdata",
    ExternalCardList: "/api/webservice/externalcard/getpaymentdata",
    SubmitPayment: "/api/webservice/externalcard/submitpayment",
    ArticleSalesNetworkSalesOrderList: "/api/articlesalesnetworksalesorder/getlistdata",
    ArticleImages: "/api/article/getarticleimages",
    ArticleImageUpdate: "/api/article/saveimages",
    WarrantyRequestList: "/api/warrantyrequest/getlistdata",
    WarrantyRequestLoad: "/api/warrantyrequest/resetformdata",
    WarrantyRequestUpdate: "/api/warrantyrequest/updateformdata",
    WarrantyRequestDelete: "/api/warrantyrequest/deleteformdata",
    WarrantyRequestAccepted: "/api/warrantyrequest/accepted",
    WarrantyRequestDenied: "/api/warrantyrequest/denied",
    WarrantyRequestPersonSatcUpdate: "/api/warrantyrequest/personnesatc",

    SalesNetworkNatureCodeList: "/api/salesnetworknaturecode/getlistdata",
    SalesNetworkNatureCodeUpdate: "/api/salesnetworknaturecode/updateformdata",
    SalesNetworkNatureCodeLoad: "/api/salesnetworknaturecode/resetformdata",
    SalesNetworkNatureCodeDelete: "/api/salesnetworknaturecode/deleteformdata",

    ClubSendEmail: "/api/webservice/club/sendemail",

    SkillList: "/api/skill/getlistdata",
    SkillLoad: "/api/skill/resetformdata",
    SkillUpdate: "/api/skill/updateformdata",
    SkillDelete: "/api/skill/deleteformdata",

    FeeList: "/api/fee/getlistdata",
    FeeLoad: "/api/fee/resetformdata",
    FeeUpdate: "/api/fee/updateformdata",
    FeeDelete: "/api/fee/deleteformdata",

    FeeExport: "/api/fee/exportdata",
    FeeImport: "/api/fee/importdata",

    DefectCodeList: "/api/defectcode/getlistdata",
    DefectCodeLoad: "/api/defectcode/resetformdata",
    DefectCodeUpdate: "/api/defectcode/updateformdata",
    DefectCodeDelete: "/api/defectcode/deleteformdata",

    CoverageList: "/api/coverage/getlistdata",
    CoverageLoad: "/api/coverage/resetformdata",
    CoverageUpdate: "/api/coverage/updateformdata",
    CoverageDelete: "/api/coverage/deleteformdata",

    SettingsList: "/api/appsettings/getlistdata",
    SettingsUpdate: "/api/appsettings/updateformdata",
    SettingsLoad: "/api/appsettings/resetformdata",
    SettingsDelete: "/api/appsettings/deleteformdata",

    SocieteSkillList: "/api/societeskill/getlistdata",
    SocieteSkillLoad: "/api/societeskill/resetformdata",
    SocieteSkillUpdate: "/api/societeskill/updateformdata",
    SocieteSkillDelete: "/api/societeskill/deleteformdata",
    SocieteSkillAdd: "/api/societeskill/addskills",
    SocieteSkillBatchUpload: "/api/societeskill/batchupload",

    FamillySkillList: "/api/famillyskill/getlistdata",
    FamillySkillLoad: "/api/famillyskill/resetformdata",
    FamillySkillUpdate: "/api/famillyskill/updateformdata",
    FamillySkillDelete: "/api/famillyskill/deleterelationdata",
    FamillySkillAdd: "/api/famillyskill/addskills",

    SocietePostalCodeList: "/api/societepostalcode/getlistdata",
    SocietePostalCodeUpdate: "/api/societepostalcode/updateformdata",
    SocietePostalCodeLoad: "/api/societepostalcode/resetformdata",
    SocietePostalCodeDelete: "/api/societepostalcode/deleteformdata",

    SocieteTechnisianList: "/api/societetechnisian/getlistdata",
    SocieteTechnisianUpdate: "/api/societetechnisian/updateformdata",
    SocieteTechnisianLoad: "/api/societetechnisian/resetformdata",
    SocieteTechnisianDelete: "/api/societetechnisian/deleteformdata",

    UniversSitesList: "/api/universsites/getlistdata",
    UniversSitesUpdate: "/api/universsites/updateformdata",
    UniversSitesLoad: "/api/universsites/resetformdata",
    UniversSitesDelete: "/api/universsites/deleteformdata",
    UniversSitesSSOList: "/api/universsitessso/getlistdata",
    UniversSitesSSOUpdate: "/api/universsitessso/updateformdata",
    UniversSitesSSOLoad: "/api/universsitessso/resetformdata",
    UniversSitesSSODelete: "/api/universsitessso/deleteformdata",
    UniverseSitesReseauxList: "/api/universesitesreseaux/getlistdata",
    UniverseSitesReseauxUpdate: "/api/universesitesreseaux/updatereseaux",
    SSOList: "/api/sso/getlistdata",
    SSOUpdate: "/api/sso/updateformdata",
    SSOLoad: "/api/sso/resetformdata",
    SSODelete: "/api/sso/deleteformdata",
    MaintenanceList: "/api/maintenance/getlistdata",
    MaintenanceUpdate: "/api/maintenance/updateformdata",
    MaintenanceLoad: "/api/maintenance/resetformdata",
    MaintenanceDelete: "/api/maintenance/deleteformdata",

    SetupTranslationList: "/api/setupuitranslations/getlistdata",
    SetupTranslationUpdate: "/api/setupuitranslations/updateformdata",
    SetupTranslationLoad: "/api/setupuitranslations/resetformdata",
    SetupTranslationDelete: "/api/setupuitranslations/deleteformdata",

    SetupUITranslationsImport: "/api/setupuitranslations/importdata",
    SetupUITranslationsExport: "/api/setupuitranslations/exportdata",
}

/**
 * basic user roles for proejctthe url endings for each action of api
 */
export const UserRoles = {
    SUPERADMIN: "superadmin",
    ADMIN: "admin",
    SATC: "satc",
    DISPATCHER: "dispatcher"
}

export const SatcType = {
    SATC_PAS_DEMANDE: 'non-demandee',
    SATC_ATTENTE: 'en-cours',
    SATC_VALIDE: 'acceptee',
    SATC_REFUSE: 'refusee'
}

export const StatusCommande = {
    ATTENTE_SATC: 'attente-satc',
    CREEE: 'creee',
    ATTENTE: 'attente',
    EXPEDIEE: 'expediee',
    SOLDEE: 'soldee',
    ANNULEE: 'annulee',
    ATTENTE_PAIEMENT: 'attente-paiement',
    VALIDEE: 'validee',
    REFUSEE_PRESTATAIRE_PAIMENT: 'refusee-prestataire-paiement',
    ATTENTE_BANQUE: 'attente-banque'
}

export const ValideCommande = {
    VALIDE_EN_COURS: 'en-cours',
    VALIDE_VALIDE: 'validee',
    VALIDE_EXPORTE: 'expediee',
    VALIDE_TRANSMISE: 'transmise',
    VALIDE_ACCEPTEE: 'acceptee',
    VALIDE_REFUSEE: 'refusee',
    VALIDE_PARTIEL: 'partielle',
    VALIDE_NON_RECEVABLE: 'non-recevable'
}

/**
 * basic Schedule Types for the proejct services
 */
export const ScheduleType = {
    SECOND: 'second',
    MINUTE: 'min',
    HOUR: 'hour',
    DAILY: 'day',
    WEEKLY: 'week',
    MONTHLY: 'month',
    YEARLY: 'year'
}

export const UniversConfig = {
    sauter: {
        name: "sauter",
        color: "#75af4a"
    },
    therpac: {
        name: "therpac",
        color: "grey",
    },
    atlantic: {
        name: "atlantic",
        color: "red",
    },
    default: {
        name: "sauter",
        color: "#75af4a"
    },
};

