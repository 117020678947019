import React from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { /*Input,*/ Textarea } from '../../../Shared/Input';

export default function Settings(props) {

    const columnConfig = [
        {
            text: 'text.code',
            field: 'code',
            type: COLUMN_TYPE.TEXT,
            readOnly: true,
            width: 400,
        },
        {
            text: 'text.value',
            field: 'value',
            type: COLUMN_TYPE.TEXT,
            editor: {
                customRenderer: (props) => { // NOSONAR
                    const { editingRecord, setEditingRecord } = props;
                    return (
                        <Textarea type='text' value={editingRecord.value}
                            onChange={(e) => { // NOSONAR
                                setEditingRecord({
                                    ...editingRecord, value: e.target.value
                                });
                            }}
                            className={'ant-input'} />
                    )
                }
            }
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            listName={AppPages.Settings}
            columnConfig={columnConfig}
            filters={[{ name: 'code', type: 'text', text: 'text.code' }]}
            apiUrl={ApiUrl.SettingsList}
            inlineUpdateUrl={ApiUrl.SettingsUpdate}
            editable
            listUpdateEvent={EVENT_NAME.SETTINGS_LIST_UPDATE}
            skipNewButton
            noPaging
        />

    );
}
